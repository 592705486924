// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require('jquery');
require('./semantic.min');
const ClipboardJS = require('clipboard/dist/clipboard.min');
const anime = require('animejs/lib/anime.min');
window.prediction = {};
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
document.addEventListener('turbolinks:load', function () {
    $('.message .close').on('click', function() {
        $(this).closest('.message').transition('fade');
    });

    $('.heart').transition('set looping').transition('pulse', '1200ms');

    let clipboard = new ClipboardJS('.button');

    clipboard.on('success', function(e) {
        $('#success_copy').show();
        e.clearSelection();
    });

    prediction.run = function () {
        let randomizer = {};
        randomizer.opacityIn = [0, 1];
        randomizer.scaleIn = [0.2, 1];
        randomizer.scaleOut = 3;
        randomizer.durationIn = 800;
        randomizer.durationOut = 600;
        randomizer.delay = 500;

        anime.timeline({loop: false}).add({
            targets: '#randomizer .item-1',
            opacity: randomizer.opacityIn,
            scale: randomizer.scaleIn,
            duration: randomizer.durationIn
        }).add({
            targets: '#randomizer .item-1',
            opacity: 0,
            scale: randomizer.scaleOut,
            duration: randomizer.durationOut,
            easing: "easeInExpo",
            delay: randomizer.delay
        }).add({
            targets: '#randomizer .item-2',
            opacity: randomizer.opacityIn,
            scale: randomizer.scaleIn,
            duration: randomizer.durationIn
        }).add({
            targets: '#randomizer .item-2',
            opacity: 0,
            scale: randomizer.scaleOut,
            duration: randomizer.durationOut,
            easing: "easeInExpo",
            delay: randomizer.delay
        }).add({
            targets: '#randomizer .item-3',
            opacity: randomizer.opacityIn,
            scale: randomizer.scaleIn,
            duration: randomizer.durationIn
        }).add({
            targets: '#randomizer .item-3',
            opacity: 0,
            scale: randomizer.scaleOut,
            duration: randomizer.durationOut,
            easing: "easeInExpo",
            delay: randomizer.delay
        }).add({
            targets: '#randomizer .item-4',
            opacity: randomizer.opacityIn,
            scale: randomizer.scaleIn,
            duration: randomizer.durationIn
        }).add({
            targets: '#randomizer .item-4',
            opacity: 0,
            scale: randomizer.scaleOut,
            duration: randomizer.durationOut,
            easing: "easeInExpo",
            delay: randomizer.delay
        }).add({
            targets: '#randomizer .item-5',
            opacity: randomizer.opacityIn,
            scale: randomizer.scaleIn,
            duration: randomizer.durationIn
        }).add({
            targets: '#randomizer .item-5',
            opacity: 0,
            scale: randomizer.scaleOut,
            duration: randomizer.durationOut,
            easing: "easeInExpo",
            delay: randomizer.delay
        }).add({
            targets: '#randomizer .result',
            opacity: randomizer.opacityIn,
            scale: randomizer.scaleIn,
            duration: randomizer.durationIn
        });
    }
});
